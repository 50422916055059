import { request } from "@/request/http";

/**
 * @name: 日程页接口
 * @author: camellia
 * @date: 2022-02-28 
 */
export default {
  // 获取首页数据
  getLogList: (data) => {
    return request("POST", `/index.php/backAdminLog/getLogList`, data);
  },
  // 获取日志操作用户列表
  getHandleUserList: (data) => {
    return request("POST", `/index.php/backAdminLog/getHandleUserList`, data);
  },
};