import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { startLoading, endLoading } from "@/utils/function";
import API from '@/api/manager/log/logList';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    // 页面名称
    name: "questionList",
    data() {
        return {
            // 日志列表数据
            logList: [],
            // 日志数据总数
            logListTotal: 0,
            // 根据日志内容搜索
            searchContent: "",
            // 根据用户名搜索
            searchUser: '',
            // 根据时间搜索
            searchTime: '',
            // 用户列表
            userList: '',
            // 当前页码数
            currentPage: 1,
            // 快捷时间选择
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
            value2: ''
        }
    },
    /**
     * @name: 生命周期创建
     * @desc: 页面创建时获取数据
     * @author: lxy
     * @date: 20210908
     */
    created() {
        let self = this;
        self.getData()
    },
    methods:
    {
        /**
         * @name: 导出日志
         * @author: lxy
         * @date: 20210908
         */
        exportLog() {
            let self = this;
            // 搜索参数
            let data = {
                startDate: self.searchTime ? self.searchTime[0] : '',
                endDate: self.searchTime ? self.searchTime[1] : '',
                user_id: self.searchUser,
                search: self.searchContent,
                page: self.currentPage
            };
        },
        /**
         * @name: 获取日志列表
         * @author: lxy
         * @date: 20210908
         */
        getData() {
            try {
                let self = this;
                // 请求参数
                let data = {
                    startDate: self.searchTime ? self.searchTime[0] : '',
                    endDate: self.searchTime ? self.searchTime[1] : '',
                    user_id: self.searchUser,
                    search: self.searchContent,
                    page: self.currentPage
                };
                nprogressStart();
                self.$Loading.show();
                // 获取日志列表请求
                API.getLogList(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            self.logList = result.logList
                            self.logListTotal = result.number - 0
                        }
                        else {
                            //  Message.error('系统错误')
                            this.$Tips({
                                // 消息提示内容
                                message: '系统错误',
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                //  Message.error('系统错误') 
                this.$Tips({
                    // 消息提示内容
                    message: '系统错误',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
        },
        /**
         * @name: 搜索用户
         * @author: lxy
         * @date: 20210908
         */
        handleSearchUser(e) {
            try {
                let self = this;
                // 请求参数
                let data = {
                    search: e
                }
                // 获取日志操作用户列表请求
                API.getHandleUserList(data)
                    .then((result) => {
                        if (result.code > 0) {
                            self.userList = result.userList;
                        }
                        else {
                            // Message.error('系统错误')

                            this.$Tips({
                                // 消息提示内容
                                message: '系统错误',
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误')

                this.$Tips({
                    // 消息提示内容
                    message: '系统错误',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
        },
        /**
         * @name: 分页获取日志
         * @desc: 根据页码获取试题
         * @author: lxy
         * @date: 20210908
         */
        handleCurrentChange(index) {
            try {
                let self = this;
                self.currentPage = index
                self.getData()
            }
            catch (error) {
                // Message.error('系统错误');
                this.$Tips({
                    // 消息提示内容
                    message: '系统错误',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })

            };
        },
        /**
         * @name: 根据搜索条件,获取日志
         * @author: lxy
         * @date: 20210908
         */
        handleSearchLog() {
            try {
                let self = this;
                self.currentPage = 1;
                self.getData();
            }
            catch (error) {
                // Message.error('系统错误');
                this.$Tips({
                    // 消息提示内容
                    message: '系统错误',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })

            };
        },
    }
};
