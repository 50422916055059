<template >
    <div class="logList">
        <div class="logButton clearFix">
            <div class="export">
                <el-button type="primary" @click="exportLog">导出日志</el-button>
            </div>
            <div class="search">
                <div class="searchTime">
                    <el-date-picker
                        v-model="searchTime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        :picker-options="pickerOptions"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                        </el-date-picker>
                </div>
                <div class="searchUser">
                    <el-select v-model="searchUser" clearable filterable placeholder="请选择用户" :filter-method="handleSearchUser">
                        <el-option
                        v-for="item in userList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <!-- 搜索日志 -->
                <div class="searchContent">
                    <el-input placeholder="请输入内容" v-model="searchContent" class="input-with-select"></el-input>

                </div> 
                <el-button type="primary" @click="handleSearchLog">搜索</el-button>
            </div>
        </div>
        <!-- 日志列表 -->
        <el-card>
            <el-table
                ref="multipleTable"
                :data="logList"
                tooltip-effect="dark"
                style="width: 100%">
                    <!-- 序号 -->
                    <el-table-column
                      type="index"
                      label="序号"
                      width="100">
                    </el-table-column>
                    <!-- 操作记录 -->
                    <el-table-column
                      prop="handle"
                      label="操作记录">
                    </el-table-column>
                    <!-- 用户 -->
                      <el-table-column
                      prop="username"
                      label="用户">
                    </el-table-column>
                    <!-- 时间-->
                    <el-table-column
                    prop="addtime"
                    label="时间">
                </el-table-column>
            </el-table>
            <!-- 日志分页 -->
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                background
                :page-size=20
                layout="prev, pager, next"
                :total="logListTotal">
            </el-pagination>
        </el-card>
    </div>
  
</template>

<script>
    // 引入试题列表js
    import logList from "@/assets/js/manager/log/logList.js";
    export default {
     ...logList
    }
</script>

<style lang="scss" scoped>
// 引入试题列表组件css
    @import "@/assets/css/manager/log/logList.scss";
</style>
